import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";

import { MainContext } from "../Providers/MainContext";
import { useTranslation } from "react-i18next";
import useFetchConfig from "../Hooks/useFetchConfig";
import { useFetch } from "use-http";
import { isObjectNotEmpty } from "../Utils/utilFunctions";
import CustomerDropdown from "./CustomerDropdown";
import QuickMessagePopup from "./QuickMessagePopup";
import { pollTaskStatus } from "../Utils/utilFunctions";

export default function LoggedInAppBar() {
  const {
    user,
    customer,
    setJwt,
    setCustomer,
    setUser,
    setImpersonatedCustomerId,
    canImpersonate,
    setCanImpersonate,
  } = useContext(MainContext);
  const navigate = useNavigate();
  // Extract i18n along with t so we can change the language on the client side.
  const { t, i18n } = useTranslation();
  const [dashboards, setDashboards] = useState([]);
  const [dailyReports, setDailyReports] = useState([]);
  const [eocrReports, setEocrReports] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const fetchConfig = useFetchConfig();
  const { get, patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  useEffect(() => {
    if (customer.id) {
      fetchCampaigns(customer);
      fetchDailyReports(customer);
      fetchEocrReports(customer);
    }
  }, [customer]);

  useEffect(() => {
    if (canImpersonate) {
      fetchCustomers();
    }
  }, []);

  const fetchCampaigns = async (c) => {
    if (!c.id) return;
    const currentCustomerId = c.id;
    let data = await get(`/api/customer/${c.id}/dashboards`);
    if (response.ok && customer.id === currentCustomerId) {
      setDashboards(data);
    }
  };

  const fetchCustomers = async () => {
    let data = await get("/api/customer/all");
    if (response.ok) {
      setCustomers(data);
    }
  };

  const fetchDailyReports = async (c) => {
    const currentCustomerId = c.id;
    let data = await get(`/api/reports/customer/${c.id}/daily/list`);
    if (response.ok) {
      if (customer.id === currentCustomerId) {
        setDailyReports(data);
        return;
      }
    }
    setDailyReports([]);
  };

  const fetchEocrReports = async (c) => {
    const currentCustomerId = c.id;
    let data = await get(`/api/reports/customer/${c.id}/eocr/list`);
    if (response.ok) {
      if (customer.id === currentCustomerId) {
        setEocrReports(data);
        return;
      }
    }
    setEocrReports([]);
  };

  const _handleLogout = () => {
    setJwt("");
    setCustomer({});
    setUser({});
    setImpersonatedCustomerId(null);
    setCanImpersonate(false);
    navigate("/");
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleModelledGroupingsPull = async () => {
    const data = await patch("/api/modelled-groupings");
    if (response.ok) {
      const task_id = data.task_id;
      pollTaskStatus(task_id, get, handleMGPResolution, response);
      return;
    }
    setPopUpMessage("Update for Stores/Merch failed fast.");
    setShowPopup(true);
    return;
  };

  function handleMGPResolution(data, response) {
    if (response.ok) {
      setPopUpMessage(data.result);
      setShowPopup(true);
      return;
    }
  }

  const handleLanguageChange = async (lang) => {
    await patch("/api/me", { lang });
    if (response.ok) {
      // Here we map the server language codes to the codes used by i18next.
      const languageMap = { EN: "en", FR: "fr", DE: "de" };
      i18n.changeLanguage(languageMap[lang]);
    } else {
      setPopUpMessage("Language update failed.");
      setShowPopup(true);
    }
  };

  const userIsCustomerAdmin =
    customer.admins &&
    customer.admins.map((_user) => _user.id).includes(user.id);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        {customer.image !== null && isObjectNotEmpty(customer) && (
          <Navbar.Brand onClick={() => navigate(-1)}>
            <Image src={customer.image} style={{ height: 64 }} />
          </Navbar.Brand>
        )}
        {!customer.hide_branding && (
          <Navbar.Brand onClick={() => navigate(-1)}>
            <Image src="/static/logo-blue.png" style={{ height: 64 }} fluid />
          </Navbar.Brand>
        )}
        {canImpersonate && (
          <Navbar.Text>Customer: {customer.title}</Navbar.Text>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => navigate("/home")}>{t("Home")}</Nav.Link>
            {canImpersonate && (
              <Nav.Link onClick={() => navigate("/jobs")}>Job Hopper</Nav.Link>
            )}
            {dashboards.length > 1 && (
              <NavDropdown title="Campaign Selector" id="basic-nav-dropdown">
                {dashboards.map((dashboard) => (
                  <NavDropdown.Item
                    key={dashboard.id}
                    onClick={() => navigate(`/dashboard/${dashboard.id}`)}
                  >
                    {dashboard.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            )}
            {dailyReports.length > 0 && (
              <NavDropdown
                title={t("Daily Report Selector")}
                id="basic-nav-dropdown"
              >
                {dailyReports.map((report) => (
                  <li
                    key={`${report.id}-${report.slug}`}
                    className="d-flex justify-content-center mb-1"
                  >
                    <a
                      href={`/api/reports/dashboard/${report.id}/daily/${report.slug}`}
                      target="_self"
                      className="btn btn-primary text-white w-100 mx-1"
                    >
                      {report.title}
                    </a>
                  </li>
                ))}
              </NavDropdown>
            )}
            {eocrReports.length > 0 && (
              <NavDropdown title={t("EOCR Selector")} id="basic-nav-dropdown">
                {eocrReports.map((report) => (
                  <li
                    key={`EOCR-${report.id}`}
                    className="d-flex justify-content-center mb-1"
                  >
                    <a
                      href={`/api/reports/dashboard/${report.id}/eocr`}
                      target="_self"
                      className="btn btn-primary text-white w-100 mx-1"
                    >
                      {report.title}
                    </a>
                  </li>
                ))}
              </NavDropdown>
            )}
            <CustomerDropdown
              customers={customers}
              canImpersonate={canImpersonate}
              setImpersonatedCustomerId={setImpersonatedCustomerId}
            />
            <NavDropdown title={t("Settings")} id="basic-nav-dropdown" align="end">
              <NavDropdown.Item onClick={() => navigate("/password/edit")}>
                {t("Change Password")}
              </NavDropdown.Item>
              <NavDropdown
                title={t("Language")}
                id="language-nav-dropdown"
                align="end"
                className="ms-2"
              >
                <NavDropdown.Item onClick={() => handleLanguageChange("EN")}>
                  {t("English")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLanguageChange("FR")}>
                  {t("Français")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLanguageChange("DE")}>
                  {t("Deutsch")}
                </NavDropdown.Item>
              </NavDropdown>
              {(userIsCustomerAdmin || canImpersonate) && (
                <>
                  <NavDropdown.Item onClick={() => navigate("/admin-settings")}>
                    {t("Admin Settings")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => navigate("/locations")}>
                    {t("Locations")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/products")}>
                    {t("Products")}
                  </NavDropdown.Item>
                </>
              )}
              {canImpersonate && (
                <>
                  <NavDropdown.Item onClick={() => navigate("/customers")}>
                    {t("Customers")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => navigate("/metrictemplate-create")}>
                    {t("New MetricTemplate")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleModelledGroupingsPull}>
                    {t("Pull Modelled-Groupings")}
                  </NavDropdown.Item>
                  <QuickMessagePopup
                    show={showPopup}
                    onHide={handleClosePopup}
                    title="Modelled Groupings Update:"
                    message={popUpMessage}
                  />
                  <NavDropdown.Divider />
                </>
              )}
              <NavDropdown.Item onClick={_handleLogout}>
                {t("Logout")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
