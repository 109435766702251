// frontend/src/Components/ProfileFieldsForm.js
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const renderTooltip = (txt, message) => (
  <OverlayTrigger
    placement="right"
    overlay={<Tooltip className="primary-tooltip">{message}</Tooltip>}
  >
    <span>{txt}</span>
  </OverlayTrigger>
);

export default function ProfileFieldsForm({
  profile,
  errors,
  dashboards,
  customers,
  onChange,
}) {
  return (
    <>
      {/* Language Selection */}
      <Form.Group className="mb-3">
        <Form.Label className="text-light">
          {renderTooltip("Language", "Select the user's preferred language.")}
        </Form.Label>
        <Form.Control
          as="select"
          name="lang"
          value={profile.lang}
          onChange={onChange}
        >
          <option value="EN">English</option>
          <option value="FR">French</option>
          <option value="DE">German</option>
        </Form.Control>
      </Form.Group>

      {/* MCA Toggle and Multi-Customer Selection */}
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label={renderTooltip(
            "Multi-Customer Admin (MCA)",
            "Grants admin access across multiple customers.",
          )}
          name="mca"
          checked={profile.mca}
          onChange={onChange}
          className="text-light"
        />
      </Form.Group>

      {profile.mca ? ( customers &&
        <Form.Group className="mb-3">
          <Form.Label className="text-light">
            {renderTooltip(
              "MCA Customers",
              "If MCA access should be limited, select specific customers to administer.",
            )}
          </Form.Label>
          <Form.Control
            as="select"
            multiple
            name="mca_customers"
            value={profile.mca_customers}
            onChange={onChange}
          >
            {customers.map((c) => (
              <option key={c.id} value={c.id}>
                {c.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      ) : (
        <>
          {/* Customer Admin Toggle */}
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label={renderTooltip(
                "Customer Admin",
                "Provides full admin access for the customer.",
              )}
              name="customer_admin"
              checked={profile.customer_admin}
              onChange={onChange}
              className="text-light"
            />
          </Form.Group>

          {/* Only show dashboard permissions if not a customer admin */}
          {!profile.customer_admin && dashboards && (
            <>
              {/* Dashboard Admin Multi-Select */}
              <Form.Group className="mb-3">
                <Form.Label className="text-light">
                  {renderTooltip(
                    "Dashboard Admin Access",
                    "Set user as dashboard-admin; this will replace existing dash-admin.",
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  name="dashboard_admin_ids"
                  value={profile.dashboard_admin_ids}
                  onChange={onChange}
                >
                  {dashboards &&
                    dashboards.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.title}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>

              {/* Dashboard Viewer Multi-Select */}
              <Form.Group className="mb-3">
                <Form.Label className="text-light">
                  {renderTooltip(
                    "Dashboard Viewer Access",
                    "Select dashboards for view-only access.",
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  name="dashboard_viewer_ids"
                  value={profile.dashboard_viewer_ids}
                  onChange={onChange}
                >
                  {dashboards &&
                    dashboards.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.title}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
}
