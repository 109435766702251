import React, { useRef, useEffect, useState } from "react";

const DoubleScrollerTableWrapper = ({ children }) => {
  const topScroll = useRef(null);
  const bottomScroll = useRef(null);
  const contentRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setTableWidth(contentRef.current.scrollWidth);
    }

    const syncScroll = (source, target) => {
      if (!source || !target) return;
      const handler = () => {
        if (target.scrollLeft !== source.scrollLeft) {
          target.scrollLeft = source.scrollLeft;
        }
      };
      source.addEventListener("scroll", handler);
      return () => source.removeEventListener("scroll", handler);
    };

    const cleanupTop = syncScroll(topScroll.current, bottomScroll.current);
    const cleanupBottom = syncScroll(bottomScroll.current, topScroll.current);

    return () => {
      cleanupTop?.();
      cleanupBottom?.();
    };
  }, []);

  return (
    <>
      <div
        ref={topScroll}
        style={{
          overflowX: "auto",
          height: 16, 
          marginBottom: 4,
        }}
      >
        <div style={{ width: tableWidth }} />
      </div>

      <div
        ref={bottomScroll}
        style={{ overflowX: "auto" }}
      >
        <div ref={contentRef}>{children}</div>
      </div>
    </>
  );
};

export default DoubleScrollerTableWrapper;
