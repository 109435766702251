// frontend/src/Components/GenericFilters.js
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import LabelledSearchableSwitchDropdown from "./LabelledSearchableSwitchDropdown";

const GenericFilters = ({
  filterGroups,
  selectedFilterGroups,
  onSwitchChange,
  t,
  displayOptionsFunc = null,
}) => {
  if (Object.keys(filterGroups).length > 0) {
    return (
      <>
        <br />
        <Row>
          {Object.entries(filterGroups)
            .filter(([key, values]) => values.length > 1)
            .map(([groupLabel, options]) => {
              const computedSwitchStates = {};
              options.forEach((option) => {
                computedSwitchStates[option] =
                  selectedFilterGroups[groupLabel]?.includes(option) || false;
              });
              return (
                <Col
                  key={`gen-f-${groupLabel}`}
                  className="d-flex flex-column justify-content-between"
                >
                  <Form.Label>{t(groupLabel)}</Form.Label>
                  <LabelledSearchableSwitchDropdown
                    options={options}
                    displayOptionsFunc={displayOptionsFunc}
                    placeholder={t("Filter")}
                    switchStates={computedSwitchStates}
                    onSwitchChange={onSwitchChange}
                    label={groupLabel}
                    variant="cyan"
                  />
                </Col>
              );
            })}
          {Array((4 - (Object.keys(filterGroups).length % 4)) % 4)
            .fill(null)
            .map((_, index) => (
              <Col key={`empty-${index}`} />
            ))}
        </Row>
      </>
    );
  }
  return null;
};

export default GenericFilters;
