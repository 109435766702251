import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useFilterStore from "./useFilterStore";
import { SPLIT_CHARS } from "../config";

const useUrlSync = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize from URL on mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    useFilterStore.getState().initializeFromQueryParams(params);
    useFilterStore.getState().recomputeUrlParams();
  }, []); // Empty dependency array: run once on mount

  // Subscribe to store changes and update URL
  useEffect(() => {
    return useFilterStore.subscribe((state) => {
      // Create URL parameters object
      const urlObj = {};
      
      if (state.startdate) urlObj.start_date = state.startdate;
      if (state.endDate) urlObj.end_date = state.endDate;
      
      const locations = Array.from(state.selectedLocations).sort().join(SPLIT_CHARS);
      if (locations) urlObj.locations = locations;
      
      const cities = Array.from(state.selectedCities).sort().join(SPLIT_CHARS);
      if (cities) urlObj.cities = cities;
      
      const regions = Array.from(state.selectedRegions).sort().join(SPLIT_CHARS);
      if (regions) urlObj.regions = regions;
      
      const countries = Array.from(state.selectedCountries).sort().join(SPLIT_CHARS);
      if (countries) urlObj.countries = countries;
      
      const brands = Array.from(state.selectedBrands).sort().join(SPLIT_CHARS);
      if (brands) urlObj.brands = brands;
      
      const categories = Array.from(state.selectedCategories).sort().join(SPLIT_CHARS);
      if (categories) urlObj.categories = categories;
      
      const products = Array.from(state.selectedProducts).sort().join(SPLIT_CHARS);
      if (products) urlObj.products = products;
      
      if (state.groupBy) urlObj.group_by = state.groupBy;
      if (state.overlaySelection) urlObj.overlay_selection = state.overlaySelection;

      // Handle filter groups
      Object.entries(state.selectedFilterGroups).forEach(([group, values]) => {
        const groupValues = Array.from(values).sort().join(SPLIT_CHARS);
        if (groupValues) urlObj[group] = groupValues;
      });

      // Sort keys for stable order
      const sortedKeys = Object.keys(urlObj).sort();
      const sortedObj = {};
      sortedKeys.forEach((key) => {
        sortedObj[key] = urlObj[key];
      });

      const queryString = new URLSearchParams(sortedObj).toString();
      const currentSearch = location.search.slice(1);

      if (queryString !== currentSearch) {
        navigate({ search: queryString }, { replace: true });
      }
    });
  }, [navigate, location.search]);
};

export default useUrlSync;