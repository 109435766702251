import { graphColorsJSON, blackColor } from "./GraphColors";

// Utility function to check if an object is not empty
function isObjectNotEmpty(obj) {
  return obj && Object.keys(obj).length > 0;
}

function sortArrayByDcountAndValue(array) {
  return array.sort((a, b) => {
    // Primary sort based on dcount (descending)
    const dcountDifference = b.dcount - a.dcount;
    if (dcountDifference !== 0) return dcountDifference;

    // Secondary sort based on value (alphabetically ascending)
    return a.value.localeCompare(b.value);
  });
}

function firstLetterCaps(str) {
  return str
    .toLowerCase() // Convert the whole string to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/^\w/, c => c.toUpperCase()); // Capitalize the first letter of the string
}

function countDigits(number) {
  // Convert number to string and replace '.', then get the length
  return Math.abs(number).toString().replace('.', '').length;
}

function findMaxDigits(arr) {
  return Math.max(...arr.map(number => countDigits(number)));
}

function arraysToObject(keys, values) {
  let result = {};
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = values[i];
  }
  return result;
}

function concatenateArrays(arr1, arr2, joinStr = ': ') {
  let result = [];
  for (let i = 0; i < arr1.length; i++) {
    result.push(`${arr1[i]}${joinStr}${arr2[i]}`);
  }
  return result;
}

function extractValuesFromJSON(jsonObj, keys) {
  let values = [];
  for (let key of keys) {
    if (jsonObj.hasOwnProperty(key)) {
      values.push(jsonObj[key]);
    } else {
      values.push(undefined);
    }
  }
  return values;
}

function dbToLocale(db) {
  switch (db) {
    case "DE":
      return "de-DE";
    case "FR":
      return "fr-FR";
    case "UK":
      return "en-UK";
    case "US":
      return "en-US";
    default:
      return "en-CA";
  }
}
const formatTimestamp = (tsString, locale) => {
  if (!tsString) return;
  // const locale = navigator.language; // Auto-detects locale
  const date = new Date(tsString);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
  }).format(date);
};

function formatStdDateLocaleTime(tsString, locale = 'de-DE') {
  if (!tsString) return;
  try {
    const date = new Date(tsString);
    // Format the date in YYYY-MM-DD format

    const dateFormatter = new Intl.DateTimeFormat('en-CA', { // 'en-CA' uses the YYYY-MM-DD format natively
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    // Format the time according to the user's locale
    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;

  } catch (error) {
    return tsString;
  }

}

function formatTimeToLocale(timeString, locale = 'de-DE') {
  if (!timeString) return;

  try {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');

    // Create a new Date object with today's date, and the provided time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds || 0); // Default to 0 if seconds are not provided
    const use12Hour = uses12HourClock(locale);

    // Format the time according to the user's locale
    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: seconds ? '2-digit' : undefined, // Include seconds only if present
      hour12: use12Hour,
    });
    return timeFormatter.format(date);

  } catch (error) {
    return timeString; // Return the original string if any error occurs
  }
}

function uses12HourClock(locale = 'en-US') {
  const date = new Date();

  const formattedTime = new Intl.DateTimeFormat(locale, {
    hour: 'numeric'
  }).format(date);

  // If the formatted time includes 'AM' or 'PM', it's a 12-hour clock
  return formattedTime.includes('AM') || formattedTime.includes('PM');
}

function getColorFromPercentage(numerator, denominator, lowerBound, upperBound, lowerBetter = true, colors = ["danger", "purple", "primary"]) {
  if (!denominator || colors.length < 3) {
    throw new Error("Invalid input: Denominator must not be zero and three colors must be provided.");
  }
  // Calculate the percentage
  const percentage = (numerator / denominator) * 100;
  // Determine and return color based on the percentage range
  if (percentage < lowerBound) {
    if (lowerBetter) {
      return colors[2];
    }
    return colors[0]; // Color for below lowerBound
  } else if (percentage >= lowerBound && percentage <= upperBound) {
    return colors[1]; // Color for between lower and upper bounds
  } else {
    if (lowerBetter) {
      return colors[0];
    }
    return colors[2]; // Color for above upperBound
  }
}

function partialObject(func, partialArgs) {
  return function (newArgs) {
    return func({ ...partialArgs, ...newArgs });
  };
}

/**
 * Simplifies data for gauge charts by ensuring each array property of the provided object
 * is replaced by its first element.
 * 
 * @param {Object} data - The data object to be simplified. It modifies the original object.
 */
function simplifyDataForNotGauge(data) {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key] = data[key][0];
    }
  }
}

/**
 * Prepares chart data by adding a `displayValue` to each item in the array.
 * The `displayValue` is either the count or the percentage of the total.
 * 
 * @param {Array} data - The array of data items from chartData.value.
 * @param {string} dropdownSelection - The current dropdown selection ("percentage" or other).
 * @returns {Array} - The transformed array with updated `displayValue` for each item.
 */
function prepareCategoricalData(data, dropdownSelection) {
  let totalDcount = data.reduce((total, item) => total + item.dcount, 0);

  const dataPreparation = item => {
    if (dropdownSelection === "percentage") {
      let percent = (item.dcount / totalDcount) * 100;
      return {
        ...item,
        displayValue: percent.toFixed(2) // Optional: Format to 2 decimal places
      };
    } else {
      return {
        ...item,
        displayValue: item.dcount
      };
    }
  };

  return data.map(dataPreparation);
}


function prefersCelsius() {
  const locale = navigator.language || navigator.userLanguage;
  // Countries that primarily use Fahrenheit
  const fahrenheitCountries = ['US', 'BS', 'BZ', 'KY', 'PW'];
  // Extract the country code from the locale
  const country = locale.split('-')[1] || locale;
  return !fahrenheitCountries.includes(country.toUpperCase());
}

function celsiusToFahrenheit(celsius, decimals = 1) {
  return ((celsius * 9 / 5) + 32).toFixed(decimals);
}

function temperatureUnit(celsius) {
  if (isNaN(celsius)) return celsius;
  if (prefersCelsius()) {
    return celsius;
  }
  return celsiusToFahrenheit(celsius);
}

function applyTargetAnnotations(options, target, chartData, upper_benchmark = null, lower_benchmark = null) {
  // Check if the target is defined
  if (typeof target !== 'undefined' && target !== null) {
    options.plugins = {
      ...options.plugins,
      annotation: {
        annotations: {
          // Line annotation for the target
          line1: {
            type: 'line',
            yMin: target,  // Start the line at the target value
            yMax: target,  // End the line at the target value (horizontal line)
            borderColor: graphColorsJSON["danger"],  // Line color
            borderWidth: 2,  // Line thickness
          },
          // Label annotation for the target line
          label1: {
            type: 'label',
            xValue: chartData.name,  // Align the label with the bar
            yValue: target,  // Set the y position to the target value
            backgroundColor: graphColorsJSON["danger"],  // Label background
            color: blackColor,  // Text color
            content: `Target: ${target}`,  // The label content
            borderRadius: 4,  // Rounded corners for the label background
            position: 'center',  // Place the label at the center of the line
          }
        }
      }
    };
  }
  else if (upper_benchmark !== null && lower_benchmark !== null) {
    options.plugins = {
      ...options.plugins,
      annotation: {
        annotations: {
          // Line annotation for the upper benchmark
          line2: {
            type: 'line',
            yMin: upper_benchmark,  // Start the line at the target value
            yMax: upper_benchmark,  // End the line at the target value (horizontal line)
            borderColor: graphColorsJSON["danger"],  // Line color
            borderWidth: 2,  // Line thickness
          },
          // Label annotation for the upper benchmark line
          label2: {
            type: 'label',
            xValue: chartData.name,  // Align the label with the bar
            yValue: upper_benchmark,  // Set the y position to the target value
            backgroundColor: graphColorsJSON["danger"],  // Label background
            color: blackColor,  // Text color
            content: `Upper Benchmark: ${upper_benchmark}`,  // The label content
            borderRadius: 4,  // Rounded corners for the label background
            position: 'center',  // Place the label at the center of the line
          },

          line3: {
            type: 'line',
            yMin: lower_benchmark,  // Start the line at the target value
            yMax: lower_benchmark,  // End the line at the target value (horizontal line)
            borderColor: graphColorsJSON["danger"],  // Line color
            borderWidth: 2,  // Line thickness
          },
          // Label annotation for the upper benchmark line
          label3: {
            type: 'label',
            xValue: chartData.name,  // Align the label with the bar
            yValue: lower_benchmark,  // Set the y position to the target value
            backgroundColor: graphColorsJSON["danger"],  // Label background
            color: blackColor,  // Text color
            content: `Lower Benchmark: ${lower_benchmark}`,  // The label content
            borderRadius: 4,  // Rounded corners for the label background
            position: 'center',  // Place the label at the center of the line
          },
        }
      }
    };
  }

  return options;
}

function applyTargetAnnotationsToGrouped(options, targets, cleanData) {
  let isNotNull = value => value != null;
  if (targets.filter(isNotNull).length === 0) return options;

  options.plugins = {
    ...options.plugins,
    annotation: {
      annotations: {}
    }
  };

  const labels = Object.keys(cleanData.value);
  // get the length and divide by 2 to center the label
  const index = Math.floor(labels.length / 2);
  const label = labels[index];
  // const topThreshold = 0.95 * Math.max(...Object.values(cleanData.value));
  // instead of creating  a line for each target, create a line for all targets
  if (targets[0].target) {

    options.plugins.annotation.annotations.line1 = {
      type: 'line',
      yMin: targets[0].target,
      yMax: targets[0].target,
      xMin: -1,
      xMax: labels[labels.length],
      borderColor: graphColorsJSON["danger"],
      borderWidth: 2,
    };
    options.plugins.annotation.annotations.label1 = {
      type: 'label',
      xValue: label,
      yValue: targets[0].target,
      backgroundColor: graphColorsJSON["danger"],
      color: blackColor,
      content: `Target: ${targets[0].target}`,
      borderRadius: 4,
      // rotation: targets.length > 7 ? -90 : 0,
    };

  }
  else if (targets[0].upper_benchmark && targets[0].lower_benchmark) {
    // Upper benchmark annotation
    options.plugins.annotation.annotations[`lineUpper`] = {
      type: 'line',
      yMin: targets[0].upper_benchmark,
      yMax: targets[0].upper_benchmark,
      borderColor: graphColorsJSON["danger"],
      borderWidth: 2,
    };

    options.plugins.annotation.annotations[`labelUpper`] = {
      type: 'label',
      xValue: label,
      yValue: targets[0].upper_benchmark,
      backgroundColor: graphColorsJSON["danger"],
      color: blackColor,
      content: `Upper Benchmark: ${targets[0].upper_benchmark}`,
      borderRadius: 4,
      // rotation: targets.length > 7 ? -90 : 0,
    };

    // Lower benchmark annotation
    options.plugins.annotation.annotations[`lineLower`] = {
      type: 'line',
      yMin: targets[0].lower_benchmark,
      yMax: targets[0].lower_benchmark,
      borderColor: graphColorsJSON["danger"],
      borderWidth: 2,
    };

    options.plugins.annotation.annotations[`labelLower`] = {
      type: 'label',
      xValue: label,
      yValue: targets[0].lower_benchmark,
      backgroundColor: graphColorsJSON["danger"],
      color: blackColor,
      content: `Lower Benchmark: ${targets[0].lower_benchmark}`,
      borderRadius: 4,
      // rotation: targets.length > 7 ? -90 : 0,
    };
  }

  return options;
}

function calculateYMaxTarget(data, target, upper_benchmark, lower_benchmark) {
  // Validate input parameters
  if (!data || typeof data !== 'object') {
    console.error('Invalid data object');
    return NaN;
  }

  if (typeof data.value === 'undefined') {
    console.error('Missing data.value');
    return NaN;
  }

  const yMax = data.y_max || 0;
  return calculateYMaxHelper(data.value, yMax, target, upper_benchmark, lower_benchmark);
}

function calculateYMaxHelper(value, yMax = 0, target = null, upper_benchmark = null, lower_benchmark = null) {
  // Ensure value is a number
  const numValue = Number(value);
  if (isNaN(numValue)) {
    console.error('Invalid value:', value);
    return NaN;
  }

  // Convert parameters to numbers or null
  const numTarget = target !== null ? Number(target) : null;
  const numUpperBenchmark = upper_benchmark !== null ? Number(upper_benchmark) : null;
  const numLowerBenchmark = lower_benchmark !== null ? Number(lower_benchmark) : null;
  const numYMax = Number(yMax) || 0;

  const calculatedMax = Math.max(
    Math.max(numValue, numTarget || -Infinity, numUpperBenchmark || -Infinity, numLowerBenchmark || -Infinity) * 1.2,
    numYMax
  );
  
  // Return the minimum between calculated value and yMax if yMax exists
  return numYMax > 0 ? Math.min(calculatedMax, numYMax) : calculatedMax;
}

function getMaxValueFromTargets(targets, propertyName) {
  const maxValue = Math.max(
    ...targets
      .map(target => target?.[propertyName])
      .filter(val => val != null)
      .map(Number)
      .filter(val => !isNaN(val)),
    -Infinity
  );
  return maxValue === -Infinity ? null : maxValue;
}

function calculateYMaxTargetGrouped(data, targets = []) {
  // Validate input parameters
  if (!data || typeof data !== 'object') {
    console.error('Invalid data object');
    return NaN;
  }

  if (!data.value || typeof data.value !== 'object') {
    console.error('Invalid data.value object');
    return NaN;
  }

  if (!Array.isArray(targets)) {
    console.error('Invalid targets array');
    return NaN;
  }

  // Convert data values to numbers and filter out invalid values
  const dataValues = Object.values(data.value)
    .map(Number)
    .filter(val => !isNaN(val));

  if (dataValues.length === 0) {
    console.error('No valid numeric values in data');
    return NaN;
  }

  if (targets.length === 0) {
    return Math.max(...dataValues, data.y_max);
  }

  const maxTarget = getMaxValueFromTargets(targets, 'target');
  const maxUpperBenchmark = getMaxValueFromTargets(targets, 'upper_benchmark');
  const maxLowerBenchmark = getMaxValueFromTargets(targets, 'lower_benchmark');

  const maxDataValue = Math.max(...dataValues);
  const yMax = data.y_max || 0;

  return calculateYMaxHelper(
    maxDataValue,
    yMax,
    maxTarget,
    maxUpperBenchmark,
    maxLowerBenchmark
  );
}

function fetchAndDownloadXlsx(data, filename, response, setError, setIsLoading) {
  if (response.ok && data.result) {
    fetch(data.result)
      .then((fileResponse) => fileResponse.blob())
      .then((fileBlob) => {
        const downloadUrl = URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((err) => {
        setError("Error loading .xlsx data");
      });
  } else {
    setError("An error occurred while fetching the .xlsx data.");
  }
  setIsLoading(false);
}

async function pollTaskStatus(taskId, get, onSuccess, ...args) {
  const pollInterval = 3000; // 3 seconds

  const checkStatus = async () => {
    try {
      const data = await get(`/api/tasks/${taskId}`);

      if (data.status === "SUCCESS") {
        clearInterval(intervalId); // Stop polling

        // Execute the custom success logic with the provided arguments
        onSuccess(data, ...args);
      } else if (data.status === "FAILURE") {
        clearInterval(intervalId); // Stop polling
      }
    } catch (error) {
      console.error("Error checking task status:", error);
    }
  };

  const intervalId = setInterval(checkStatus, pollInterval);
}

/**
 * Retrieves the translation for the given language from a translations object.
 *
 * @param {object} translations - An object containing translations keyed by language code.
 *                                For example: { EN: "Hello", FR: "Bonjour" }.
 * @param {string} [lang="EN"] - The language code to retrieve (defaults to "EN").
 * @returns {string} - The translated text, or an empty string if not found.
 */
function getTranslation(translations, lang = "EN") {
  if (translations && typeof translations === "object") {
    return translations[lang] || translations["EN"] || "";
  }
  return "";
}

/**
 * Formats a date string from "yyyy-mm-dd" to "dd/mm/yyyy" if the string is in that format and lang is "FR".
 * If the string doesn't match the yyyy-mm-dd pattern or lang is not "FR", returns the original string.
 *
 * @param dateStr - The input string, which might be a date.
 * @param lang - The language code (e.g., "FR", "EN"). Defaults to "EN".
 * @returns The formatted date string or the original string.
 */
function formatDate(dateStr, lang = "EN") {
  if (!lang) return dateStr;
  if (lang.toUpperCase() === "FR") {
    // Check if the string matches the yyyy-mm-dd format exactly.
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(dateStr)) {
      const [year, month, day] = dateStr.split("-");
      return `${day}/${month}/${year}`;
    }
  }
  return dateStr;
}

export {
  isObjectNotEmpty,
  sortArrayByDcountAndValue,
  firstLetterCaps,
  countDigits,
  findMaxDigits,
  arraysToObject,
  concatenateArrays,
  extractValuesFromJSON,
  dbToLocale,
  formatTimestamp,
  formatStdDateLocaleTime,
  formatTimeToLocale,
  getColorFromPercentage,
  partialObject,
  simplifyDataForNotGauge,
  prepareCategoricalData,
  temperatureUnit,
  prefersCelsius,
  applyTargetAnnotations,
  applyTargetAnnotationsToGrouped,
  fetchAndDownloadXlsx,
  pollTaskStatus,
  calculateYMaxTarget,
  calculateYMaxTargetGrouped,
  getTranslation,
  formatDate,
}
