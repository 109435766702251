import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateChartOptions } from "../../../Utils/chartJsOptions";
import { applyTargetAnnotations, calculateYMaxTarget } from "../../../Utils/utilFunctions";

// Accepts Chart Data as Returned by the API and renders a chart
// This class should be the only thing that changes if the api output does
export default function SingleBarChart(props) {
  const { chartData, target, fraction = false, upper_benchmark, lower_benchmark } = props;

  if (chartData.value === undefined) return <></>;
  const yMax = calculateYMaxTarget(chartData, target, upper_benchmark, lower_benchmark);
  let options = generateChartOptions({ yMax: yMax });
  options = applyTargetAnnotations(options, target, chartData, upper_benchmark, lower_benchmark);
  const chartJsSetup = {
    labels: [chartData.name],
    datasets: [
      {
        plotType: chartData.type,
        label: chartData.name,
        data: [chartData.value],
        backgroundColor: getColor(0),
        borderRadius: 8,
      },
    ],
  };

  return (
    <Chart
      type="bar"
      data={chartJsSetup}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
}
