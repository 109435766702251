// frontend/src/Utils/validation.js
import { emailRegex, passwordRegex } from "./Regex";

export const validateUserFields = (fields, currentErrors = {}, includePassword = false, t) => {
  const errors = { ...currentErrors };

  // Handle errors and clearing in a single if/else block per field
  if (fields.username) {
    delete errors.username;
  } else {
    errors.username = t("Username is required");
  }

  if (fields.first_name) {
    delete errors.first_name;
  } else {
    errors.first_name = t("First name is required");
  }

  if (fields.last_name) {
    delete errors.last_name;
  } else {
    errors.last_name = t("Last name is required");
  }

  if (fields.email) {
    if (emailRegex.test(fields.email)) {
      delete errors.email;
    } else {
      errors.email = t("Invalid email address");
    }
  } else {
    errors.email = t("Email is required");
  }

  if (includePassword) {
    if (fields.password) {
      if (!passwordRegex.test(fields.password)) {
        errors.password = t("Password must include at least one lowercase letter, one uppercase letter, one digit, one of !@#$%^&*, and be at least 8 characters long.");
      } else {
        delete errors.password;
      }
    } else {
      errors.password = t("Password is required.")
    }
  }

  return errors;
};
