import React, { useRef, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Your FilterInput component
const FilterInput = ({ label, type = "text", value, onChange }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null); // Ref for the input element

  // Focus the input field after rerender
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Refocus the input
    }
  }, [value]); // This ensures focus is applied after every render

  return (
    <Col className="d-flex flex-column justify-content-end">
      <Form.Group className="mb-3">
        <Form.Label>{t(label)}</Form.Label>
        <Form.Control
          type="date"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-light"
          ref={inputRef}
        />
      </Form.Group>
    </Col>
  );
};

export default FilterInput;
