const TFFormChart = ({ chartData }) => {
    // value is the link to the typeform
    const { value } = chartData; 
    return (
        <iframe 
            src={value}
            width="100%"
            height="100%"
            style={{ 
                minHeight: "500px", 
                border: "none",
                flex: "1 1 auto"
            }}
            title="Staff Reqeust Embed"
        />
    )
};

export default TFFormChart;
