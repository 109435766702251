import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import useFetchConfig from "../../../Hooks/useFetchConfig";
import useFetch from "use-http";
import useFilterStore from "../../../Hooks/useFilterStore";
import LoadingSpinner from "../../Metric/LoadingSpinner";
import CustomSlide from "./CustomSlide"; 
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import PhotoActionsDropdown from "./PhotoActionsDropdown";
import LoadMoreTrigger from "./LoadMoreTrigger";

import { MainContext } from "../../../Providers/MainContext";

export const Gallery = ({ urls }) => {
  const { canImpersonate, user } = useContext(MainContext);
  const filterParams = useFilterStore();
  const { likedFirst } = filterParams;
  const [currentIndex, setCurrentIndex] = useState(null);
  const [error, setError] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletePhotoId, setDeletePhotoId] = useState(null);
  const [activeUrls, setActiveUrls] = useState([]);
  const [rotating, setRotating] = useState(false);
  const [visibleCount, setVisibleCount] = useState(20); // Initial number of photos

  const fetchConfig = useFetchConfig();
  const { patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  // Initialize activeUrls based on 'urls' prop
  useEffect(() => {
    if (urls && Array.isArray(urls)) {
      const enabledUrls = urls.filter((url) => !url.disabled);
      const srcedUrls = enabledUrls.map((photo) => {
        return {
          ...photo,
          src: photo.url,
          width: null, // Initialize as null; will be set after image loads
          height: null,
          liked: photo.likes.includes(user.id), 
        }
      });
      setActiveUrls(srcedUrls);
    } else {
      console.warn("Invalid or empty 'urls' prop received.");
      setActiveUrls([]);
    }
  }, [urls, user.id]);

  const sortedUrls = useMemo(() => {
    if (likedFirst) {
      return [...activeUrls].sort((a, b) => {
        const aLiked = a.likes.includes(user.id);
        const bLiked = b.likes.includes(user.id);
        if (aLiked && !bLiked) return -1;
        if (!aLiked && bLiked) return 1;
        return 0;
      });
    }
    return [...activeUrls]; // Spread to ensure new reference
  }, [activeUrls, likedFirst, user.id]);

  const visiblePhotos = useMemo(() => sortedUrls.slice(0, visibleCount), [sortedUrls, visibleCount]);
  // Preload images and set width and height if missing
  useEffect(() => {
    const photosToLoad = visiblePhotos.filter(
      (photo) => !photo.width || !photo.height
    );

    photosToLoad.forEach((photo) => {
      const img = new Image();
      img.src = photo.src;

      img.onload = () => {
        setActiveUrls((prevUrls) =>
          prevUrls.map((p) => {
            if (p.id === photo.id) {
              return {
                ...p,
                width: img.naturalWidth,
                height: img.naturalHeight,
              };
            }
            return p;
          })
        );
      };

      img.onerror = () => {
        console.warn(`Failed to load image: ${photo.src}`);
        // Optionally, set default dimensions or handle the error as needed
        setActiveUrls((prevUrls) =>
          prevUrls.map((p) => {
            if (p.id === photo.id) {
              return {
                ...p,
                width: 200,  // Default width
                height: 200, // Default height
              };
            }
            return p;
          })
        );
      };
    });
  }, [visiblePhotos]);

  const handleRotate = useCallback(
    async (photo, rotation) => {
      setRotating(true);
      const data = await patch(`/api/answer/${photo.id}/rotate/${rotation}`);
      if (response.ok) {
        setActiveUrls((prev) =>
          prev.map((p) => {
            if (p.id === photo.id) {
              return {
                ...p,
                url: data.url,
                src: data.url,
                height: data.height,
                width: data.width,
              };
            }
            return p;
          }),
        );
      } else {
        setError(`Photo: ${photo.id} rotation save failed.`);
      }
      setRotating(false);
    }, [patch, response]);

    const handleDelete = useCallback(async () => {
      if (deletePhotoId !== null) {
        try {
          await patch(`/api/answer/${deletePhotoId}`, { disabled: true });
          if (response.ok) {
            setActiveUrls((prevUrls) =>
              prevUrls.filter((photo) => photo.id !== deletePhotoId)
            );
            setShowDeleteDialog(false);
            setDeletePhotoId(null);
          } else {
            setError(`Failed to delete photo: ${deletePhotoId}`);
          }
        } catch (err) {
          setError(`An error occurred during deletion: ${err.message}`);
        }
      }
    }, [deletePhotoId, patch, response]);

    const handleLike = useCallback(
      async (photoId) => {
        try {
          const data = await patch(`/api/answer/${photoId}/like`);
          if (response.ok) {
            setActiveUrls((prevUrls) => ( 
              prevUrls.map((url) => {
                if (url.id === photoId) {
                  return { ...url, likes: data, liked: data.includes(user.id) };
                }
                return url;
              })
            ));
          } else {
            setError(`Photo: ${photoId} like failed.`);
          }
        } catch (err) {
          setError(`An error occurred while liking: ${err.message}`);
        }
      },
      [patch, response, user.id],
    );
  
  // Handler to unlike a photo
  const handleUnlike = useCallback(
    async (photoId) => {
      try {
        const data = await patch(`/api/answer/${photoId}/unlike`);
        if (response.ok) {
          setActiveUrls((prevUrls) => (
            prevUrls.map((url) => {
              if (url.id === photoId) {
                return { ...url, likes: data, liked: data.includes(user.id) };
              }
              return url;
            })
          ));
        } else {
          setError(`Photo: ${photoId} unlike failed.`);
        }
      } catch (err) {
        setError(`An error occurred while unliking: ${err.message}`);
      }
    },
    [patch, response, user.id],
  );

  // Convert activeUrls to Lightbox slides format
  const slides = sortedUrls.map((photo) => ({
    src: photo.url,
    description: photo.description,
    liked: photo.liked,
  }));

  return (
    <>
      {visiblePhotos.length > 0 ? (
        <MasonryPhotoAlbum
          photos={visiblePhotos}
          // spacing={20}
          // componentsProps={{ container: { style: { marginBottom: 20 } } }}
          onClick={({ event, index }) => {
            if (
              event.target.closest(".dropdown") ||
              event.target.closest(".dropdown-menu")
            ) {
              return; // Skip opening the lightbox
            }
            // Otherwise, open the lightbox
            setCurrentIndex(index);
          }}
          render={{
            extras: (_, { photo }) => (
              <div className="position-absolute top-0 end-0">
                <PhotoActionsDropdown
                  photo={photo}
                  onLike={handleLike}
                  onUnlike={handleUnlike}
                  onRotate={handleRotate}
                  onDelete={(photoId) => {
                    setShowDeleteDialog(true);
                    setDeletePhotoId(photoId);
                  }}
                  rotating={rotating}
                  canImpersonate={canImpersonate}
                />
              </div>
            ),
          }}
        />
      ) : (
        <div className="text-center mt-5">
          <LoadingSpinner text="Loading photos..." size="lg" />
        </div>
      )}
      <LoadMoreTrigger onLoadMore={() => setVisibleCount((prev) => Math.min(prev + 20, sortedUrls.length))} />
      <DeleteConfirmationModal
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
      />
      <Lightbox
        slides={slides}
        open={currentIndex !== null}
        index={currentIndex}
        close={() => setCurrentIndex(null)}
        styles={{
          container: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            width: "90%",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "auto",
          },
        }}
        plugins={[Counter, Captions]}
        captions={{ descriptionTextAlign: "center" }}
        render={{ slide: CustomSlide }}
      />
      {error && <div className="warning">{error}</div>}
    </>
  );
};

export default Gallery;
