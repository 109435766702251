import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormSelect, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import useFetch from "use-http";

import { MainContext } from "../../Providers/MainContext";
import { getTranslation } from "../../Utils/utilFunctions";

import useFetchConfig from "../../Hooks/useFetchConfig";
import CustomerUserListTable from "../User/CustomerUserListTable";
import SurveysTable from "./SurveysTable";
import AdminInsightsTable from "./AdminInsightsTable";

export default function DashboardEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useContext(MainContext);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const fetchConfig = useFetchConfig();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dashboard, setDashboard] = useState(null);
  const [targetMetrics, setTargetMetrics] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [insights, setInsights] = useState([]);

  const {
    get,
    post,
    patch,
    delete: deleter,
    response,
  } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);

  useEffect(() => {
    fetchDashboard();
    fetchDashSurveys();
    fetchDashTargetMetrics();
    fetchAdminInsights();
  }, []);

  const fetchDashboard = async () => {
    let data = await get(`/api/dashboard/${id}`);
    if (response.ok) {
      setError(null);
      setDashboard(data);
      setTitle(data.title);
      setDescription(data.description);
      setViewers(data.viewers);
      setStartDate(data.start_date.substring(0, 10));
      setEndDate(data.end_date.substring(0, 10));
    } else {
      setError("Dashboard not found");
    }
  };

  const fetchDashSurveys = async () => {
    let data = await get(`/api/dashboard/${id}/surveys`);
    if (response.ok) {
      setError(null);
      setSurveys(data);
    } else {
      setError("Dash-surveys not found.");
    }
  };
  const fetchDashTargetMetrics = async () => {
    let data = await get(`/api/dashboard/${id}/metric-targets`);
    if (Array.isArray(data) && response.ok) {
      setError(null);
      setTargetMetrics(data);
    } else {
      setError("Failed to fetch target metrics.");
    }
  };

  const fetchAdminInsights = async () => {
    let data = await get(`/api/dashboard/${id}/insights`);
    if (response.ok) {
      setError(null);
      setInsights(data);
    } else {
      setError("Admin-insights not found.");
    }
  };

  const _handleAddUser = async (userId) => {
    let data = await post(`/api/dashboard/${id}/viewer/${userId}`);
    if (response.ok) {
      setError(null);
      setViewers(data.viewers);
    }
  };

  const _handleRemoveUser = async (userId) => {
    let data = await deleter(`/api/dashboard/${id}/viewer/${userId}`);
    if (response.ok) {
      setError(null);
      setViewers(data.viewers);
    }
  };

  const _handleDisableChange = async (e) => {
    const newDisabledState = e.target.checked;

    // Update the dashboard's disabled state locally
    setDashboard((prevDashboard) => ({
      ...prevDashboard,
      disabled: newDisabledState,
    }));

    // Optionally, update the backend when toggled
    await patch(`/api/dashboard/${id}`, {
      disabled: newDisabledState,
    });
    if (response.ok) {
      setError(null);
    } else {
      // Handle error if the update failed
      setError("Failed to update dashboard");
      // Revert the disabled state in case of failure
      setDashboard((prevDashboard) => ({
        ...prevDashboard,
        disabled: !newDisabledState,
      }));
    }
  };

  const labelStyle = {
    // width: "80px", /* Set a fixed width */
    height: "40px" /* Set a fixed height */,
    display: "flex" /* Establish flex container */,
    alignItems: "center" /* Vertically center the text */,
    justifyContent: "center" /* Horizontally center the text */,
  };

  const updateMetrics = (id, updates) => {
    setTargetMetrics((prev) =>
      prev.map((metric) => {
        if (metric.id === id) {
          const y_max = metric.template.y_max;
          if (y_max !== null && y_max !== undefined) {
            // Check all possible update fields against y_max
            const fieldsToCheck = ['target', 'upper_benchmark', 'lower_benchmark'];
            for (const field of fieldsToCheck) {
              if (updates[field] && updates[field] > y_max) {
                alert(`Value cannot exceed maximum of ${y_max}`);
                return metric;
              }
            }
          }
          return { ...metric, ...updates };
        }
        return metric;
      }),
    );
  };

  function indicatorOptions(metric) {
    const summed = ["sum", "sum_over_event"];
    const fixed = ["average", "fraction"];
    if (
      summed.includes(metric.template.l1_aggregation) ||
      fixed.includes(metric.template.l1_aggregation)
    ) {
      if (metric.target) {
        return (
          <>
            <option value="">None</option>
            <option value="project_average">Project Average</option>
            <option value="vs_prev_period">Vs Previous Period</option>
            <option value="vs_target">Vs Target</option>
          </>
        );
      }
      return (
        <>
          <option value="">None</option>
          <option value="project_average">Project Average</option>
          <option value="vs_prev_period">Vs Previous Period</option>
        </>
      );
    }
  }

  const getL2AggregationTooltip = (l2Aggregation) => {
    switch (l2Aggregation) {
      case 'sum':
        return 'Targets and benchmarks are calculated by multiplying the count of answers by the target value set on the dashboard edit page.'
      case 'fraction':
      case 'average':
        return 'Targets and benchmarks are fixed. This means the values you set on the dashboard edit page will appear exactly the same on L2.';
      case 'sum_over_event':
        return 'Targets and benchmarks are computed by multiplying the count of date-location entries by the target value set on the dashboard edit page.';
      default:
        return 'This metric is not supported for target setting.';
    }
  };

  return (
    <Col xs={8} md={6} lg={4} xl={4} className="mx-auto">
      <br />
      <h1 className="text-light">{"Edit Dashboard"}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {dashboard && (
        <>
          <Row>
            <Form.Group className="mb-3" controlId="dashboardEdit.title">
              <Form.Label className="text-light">{t("Title")}</Form.Label>
              <Form.Control
                className=""
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            {/* Date */}
            <Form.Group>
              <Form.Label className="text-light">{t("Start Date")}</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-light">{t("End Date")}</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="dashboardEdit.description">
              <Form.Label className="text-light">{t("Description")}</Form.Label>
              <Form.Control
                className=""
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col className="mx-auto">
              <Form>
                <Form.Group
                  className="mb-3 d-flex align-items-center"
                  controlId="dashboardDisabled.title"
                >
                  <Form.Label className="text-light my-2">
                    {t("Enabled/Disabled Status")}
                  </Form.Label>
                  <Form.Switch
                    id={`switch-disabled`}
                    checked={dashboard.disabled} // Use the switchStates prop to determine checked state
                    onChange={_handleDisableChange}
                    className="grey-green-switch btn-primary m-2"
                  />
                  <Form.Label className="text-light my-2">
                    {dashboard.disabled ? "Disabled" : "Enabled"}
                  </Form.Label>
                </Form.Group>
              </Form>
            </Col>
            <Col className="mx-auto">
              <Button
                className="w-100"
                onClick={async () => {
                  if (!title) {
                    setError("Title is required");
                    return;
                  }
                  if (!startDate) {
                    setError("Start Date is required");
                    return;
                  }
                  if (!endDate) {
                    setError("End Date is required");
                    return;
                  }
                  if (new Date(startDate) > new Date(endDate)) {
                    setError("Start Date must be before End Date");
                    return;
                  }

                  await patch(`/api/dashboard/${id}`, {
                    title: title,
                    description: description,
                    start_date: startDate,
                    end_date: endDate,
                  });
                  if (response.ok) {
                    setError(null);
                    navigate(`/dashboard/${id}`);
                  }
                }}
              >
                {t("Save")}
              </Button>
            </Col>
          </Row>
          <Row>
            <h1 className="text-light">{t("Target Setting")}</h1>
            <Col>
              {/** We only do ONE map over targetMetrics. */}
              {targetMetrics.map((targetMetric) => (
                <Row key={`row-${targetMetric.id}`} className="my-4">
                  <h3 className="text-light text-center">
                    {getTranslation(targetMetric.template.name, lang)}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className="primary-tooltip">
                          {getL2AggregationTooltip(targetMetric.template.l2_aggregation)}
                        </Tooltip>
                      }
                    >
                      <span>
                        ({targetMetric.template.l2_aggregation})
                      </span>
                    </OverlayTrigger>
                  </h3>
               
                  <Row className="align-items-center">
                    {/* Target */}
                    <Col>
                      <div className="text-light mb-2" style={labelStyle}>
                        Target
                      </div>
                      <Form.Group
                        controlId={`dashboardEdit.target-${targetMetric.id}`}
                      >
                        <Form.Control
                          type="number"
                          className="text-end"
                          style={{ paddingRight: "20px" }}
                          value={targetMetric.target ?? ""}
                          disabled={
                            !!targetMetric.lower_benchmark ||
                            !!targetMetric.upper_benchmark
                          }
                          onChange={(e) => {
                            updateMetrics(targetMetric.id, {
                              target:
                                e.target.value === null || e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                              lower_benchmark: null,
                              upper_benchmark: null,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>

                    {/* Lower Benchmark */}
                    <Col>
                      <div className="text-light mb-2" style={labelStyle}>
                        Lower Benchmark
                      </div>
                      <Form.Group
                        controlId={`dashboardEdit.lower-${targetMetric.id}`}
                      >
                        <Form.Control
                          type="number"
                          className="text-end"
                          style={{ paddingRight: "20px" }}
                          value={targetMetric.lower_benchmark ?? ""}
                          disabled={!!targetMetric.target}
                          onChange={(e) => {
                            updateMetrics(targetMetric.id, {
                              lower_benchmark:
                                e.target.value === null || e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                              target: null,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>

                    {/* Upper Benchmark */}
                    <Col>
                      <div className="text-light mb-2" style={labelStyle}>
                        Upper Benchmark
                      </div>
                      <Form.Group
                        controlId={`dashboardEdit.upper-${targetMetric.id}`}
                      >
                        <Form.Control
                          type="number"
                          className="text-end"
                          style={{ paddingRight: "20px" }}
                          value={targetMetric.upper_benchmark ?? ""}
                          disabled={!!targetMetric.target}
                          onChange={(e) => {
                            updateMetrics(targetMetric.id, {
                              upper_benchmark:
                                e.target.value === null || e.target.value === ""
                                  ? null
                                  : parseFloat(e.target.value),
                              target: null,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className="text-light mb-2" style={labelStyle}>
                        Layer 1 Indicator
                      </div>
                      <Form.Group
                        controlId={`dashboardEdit.indicator-${targetMetric.id}`}
                      >
                        <FormSelect
                          className=""
                          value={
                            targetMetric.indicator ? targetMetric.indicator : ""
                          }
                          onChange={(e) => {
                            updateMetrics(targetMetric.id, {
                              indicator: e.target.value,
                            });
                          }}
                        >
                          {indicatorOptions(targetMetric)}
                        </FormSelect>
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              ))}

              {/** A single "Save Target" button for all metrics */}
              <Button
                className="w-100 mt-3"
                onClick={async () => {
                  await post(`/api/dashboard/${id}/metric-targets`, {
                    payload: targetMetrics,
                  });
                  if (response.ok) {
                    setError(null);
                    alert("Targets Saved");
                  } else {
                    setError("Failed to save targets");
                  }
                }}
              >
                {t("Save Target")}
              </Button>
            </Col>
          </Row>

          <Row>
            <CustomerUserListTable
              title={t("Viewers")}
              selectedUserIds={viewers}
              handleAddUser={(userId) => _handleAddUser(userId)}
              handleRemoveUser={(userId) => _handleRemoveUser(userId)}
            />
          </Row>
          <Row>
            <SurveysTable
              surveys={surveys}
              dashboardId={id}
              db={dashboard.db}
            />
          </Row>
          <Row>
            <AdminInsightsTable insights={insights} dashboardId={id} />
          </Row>
        </>
      )}
      <br />
      {dashboard && (
        <Button
          variant="primary"
          className="text-white w-100"
          onClick={() => {
            navigate(`/dashboard/${id}`);
          }}
          disabled={dashboard.disabled}
        >
          View
        </Button>
      )}
      <Row style={{ height: "50px" }}></Row>
    </Col>
  );
}
