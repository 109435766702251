import React from "react";
import { Chart } from "react-chartjs-2";
import { graphColors } from "../../../Utils/GraphColors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateChartOptions } from "../../../Utils/chartJsOptions";
import { simplifyDataForNotGauge } from "../../../Utils/utilFunctions";
import zoomPlugin from "chartjs-plugin-zoom";
import { applyTargetAnnotationsToGrouped, calculateYMaxTargetGrouped } from "../../../Utils/utilFunctions";

export default function GroupBarChart(props) {
  const { chartData, targets, fraction = false } = props;

  // Handle Gauge type if necessary
  simplifyDataForNotGauge(chartData.value);

  if (chartData.value === undefined) return <></>;

  let labelArray = [];
  let valueArray = [];
  Object.keys(chartData.value).forEach((key) => {
    labelArray.push(key);
    valueArray.push(chartData.value[key]);
  });
  const yMax = calculateYMaxTargetGrouped(chartData, targets);
  let options = generateChartOptions({ valueArray, yMax: yMax });
  options = applyTargetAnnotationsToGrouped(options, targets, chartData);
  options = {
    ...options,
    plugins: { ...options.plugins, legend: { display: false } },
  };

  const chartJsSetup = {
    labels: labelArray,
    datasets: [
      {
        plotType: chartData.type,
        // label: chartData.name,
        data: valueArray,
        backgroundColor: graphColors, // consider using distinct colors if necessary
        borderRadius: 8,
      },
    ],
  };

  return (
    <Chart
      type="bar"
      data={chartJsSetup}
      options={options}
      plugins={[ChartDataLabels, zoomPlugin]}
    />
  );
}
