// frontend/src/Utils/FilterGroupUtils.js
import { useMemo } from "react";
import useFilterStore from "../Hooks/useFilterStore";

// Update selectedFilterGroups directly—no separate switch state is needed.
export const handleFilterGroupSwitchChange = (value, isChecked, label) => {
  const {
    setSelectedFilterGroups,
    selectedFilterGroups,
  } = useFilterStore.getState();

  if (isChecked) {
    const existingValues = selectedFilterGroups[label] || [];
    if (!existingValues.includes(value)) {
      setSelectedFilterGroups({
        ...selectedFilterGroups,
        [label]: [...existingValues, value],
      });
    }
  } else {
    handleRemoveFilterGroup(value, label);
  }
};

export const handleRemoveFilterGroup = (value, label) => {
  const { setSelectedFilterGroups, selectedFilterGroups } =
    useFilterStore.getState();
  const updatedValues = (selectedFilterGroups[label] || []).filter(
    (item) => item !== value
  );
  const newGroups = { ...selectedFilterGroups };
  if (updatedValues.length === 0) {
    delete newGroups[label];
  } else {
    newGroups[label] = updatedValues;
  }
  setSelectedFilterGroups(newGroups);
};

// A helper that converts an array of selected items into an object mapping each to true.
export const computeSwitchState = (selected = []) =>
  Array.from(selected).reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});

// Updated hook: it takes the current selected array and its setter, then returns a
// computed switch state (object) along with handlers to add or remove items.
export const useSwitchableSelectionState = (selected, setSelected) => {
  // Compute switch state directly from the selected array.
  const switchState = useMemo(() => computeSwitchState(selected), [selected]);

  // Handler to update selected array when an option is toggled.
  const handleToggleItem = (value, isChecked) => {
    if (isChecked) {
      // Add the value if it's not already present.
      if (!selected.includes(value)) {
        setSelected([...selected, value]);
      }
    } else {
      // Remove the value.
      setSelected(selected.filter((item) => item !== value));
    }
  };
  const handleRemoveItem = (value) => {
    handleToggleItem(value, false);
  }
  return {
    switchState,
    handleToggleItem,
    handleRemoveItem,
  };
};

export function processFilterGroups(data, setFilterGroups) {
  const filterGroupJSON = {};
  // Populate filterGroupJSON as an object with labels as keys and Sets as values
  data.forEach((group) => {
    if (!filterGroupJSON[group.label]) {
      filterGroupJSON[group.label] = new Set();
    }
    filterGroupJSON[group.label].add(group.value);
  });
  // Convert Sets to Arrays for each label in filterGroupJSON
  Object.keys(filterGroupJSON).forEach((label) => {
    filterGroupJSON[label] = [...filterGroupJSON[label]];
  });

  setFilterGroups(filterGroupJSON);
}
