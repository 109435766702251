import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Table, Row, Col } from "react-bootstrap";
import { MainContext } from "../../Providers/MainContext";
import useFetchConfig from "../../Hooks/useFetchConfig";
import useFetch from "use-http";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function CustomerListPage({ edit = true }) {
  const { setImpersonatedCustomerId } = useContext(MainContext);

  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { t } = useTranslation();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const data = await get("/api/customer/all");
      if (response.ok) {
        setCustomers(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const containerStyle = {
    maxWidth: "800px", // Adjust the max-width as needed
    margin: "0 auto", // This centers the container
  };

  const linkCol = ({ edit }) => {
    if (edit) {
      return t("Edit");
    } else {
      return t("Select");
    }
  };
  const makeButton = ({ edit, customer }) => {
    if (edit) {
      return (
        <Button
          variant="primary"
          onClick={() => navigate(`/customers/${customer.id}/edit`)}
        >
          {t("Edit")}
        </Button>
      );
    }
    return (
      <Button
        variant="primary"
        onClick={() => {
          setImpersonatedCustomerId(customer.id);
          navigate(`/dashboard-create`);
        }}
      >
        {t("Select")}
      </Button>
    );
  };

  return (
    <Container style={containerStyle}>
      {/*  */}

      <Row className="mt-4 mb-4">
        <Col xs={8} className="d-flex">
          <h2 className="text-white">{t("Customer List")}</h2>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          <Button
            variant="primary "
            className="mx-2"
            onClick={() => navigate("/customers/new")}
          >
            {t("New Customer")}
          </Button>
          {edit && (
            <Button variant="primary mx-2" onClick={() => navigate("/home")}>
              {t("Home")}
            </Button>
          )}
        </Col>
      </Row>

      <Table
        hover
        className="rounded-3 overflow-hidden"
        size="sm"
        responsive="md"
      >
        <thead>
          <tr className="border-0">
            <th>{t("Customer ID")}</th>
            <th>{t("Customer")}</th>
            <th>{t("Description")}</th>
            <th>{linkCol({ edit })}</th>
          </tr>
        </thead>
        <tbody>
          {customers &&
            customers.map((customer, index) => (
              <tr className="border-0" key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.title}</td>
                <td>{customer.description}</td>
                <td>{makeButton({ edit, customer })}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export function CustomerSelectionPage() {
  return CustomerListPage({ edit: false });
}
