import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import CustomMenu from "react-bootstrap/esm/DropdownMenu";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import useFilterStore from "../../Hooks/useFilterStore";
import useFetchConfig from "../../Hooks/useFetchConfig";
import { MainContext } from "../../Providers/MainContext";

export default function DashboardListPage() {
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer, user, canImpersonate } = useContext(MainContext);
  const [dashboards, setDashboards] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { resetAllFilters } = useFilterStore();
  useEffect(() => {
    // isFiltersRetained is set to true
    resetAllFilters(true);
    if (customer.id) {
      fetchDashboards(customer);
    }
  }, [customer]);

  const fetchDashboards = async (c) => {
    if (!c.id) return;
    const currentCustomerId = c.id;  
    let data = await get(`/api/customer/${c.id}/dashboards`);
    if (response.ok) {
      if (customer.id === currentCustomerId) {
        setError(null);
        setDashboards(data);  
      }
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Please contact Elevate Support for help.");
      }, 2000);
    }
  };

  const _renderToggle = (dashboard) => {
    const ThreeDotToggle = React.forwardRef(({ children, onClick,  }, ref) => (
      <span
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        style={{ cursor: "pointer" }}  // Ensure it's clickable
      >
        <ThreeDotsVertical />
      </span>
    ));
    if (!dashboard) return null;
    if (
      dashboard.admin &&
      dashboard.admin.id !== user.id &&
      !customer.admins.map((_admin) => _admin.id).includes(user.id) && 
      !canImpersonate
    )
      return null;
    return (
      <Dropdown>
        <Dropdown.Toggle as={ThreeDotToggle} id="dropdown-custom-components">
          Custom toggle
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          <Dropdown.Item
            eventKey="1"
            onClick={() => navigate(`/dashboard-edit/${dashboard.id}`)}
          >
            {t("Edit")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return (
    <Col xs={12} md={12} lg={12} xl={12} className="mx-auto px-4">
      <br />
      <h1 className="text-light">{t("Dashboards")}</h1>
      <br />
      {error && <p className="text-danger">{error}</p>}
      {dashboards && dashboards.length === 0 && (
        <Row>
          <p> {t("No dashboards found")} </p>
        </Row>
      )}

      <Row>
        {dashboards &&
          dashboards.map((dashboard) => (
            // Card Padding is to fix a bootstrap bug where the card overrode the title
            // If it ever looks weird, remove the padding

            <Card key={dashboard.id} style={{ width: "18rem", padding: 0, margin: "6px" }}>
              <Card.Header style={{ flex: 3 }} className="d-flex h-100">
                <Row>
                  <Col>
                    <h4>{dashboard.title} </h4>
                  </Col>
                  <Col xs={1}>
                    <h4 style={{ float: "right" }}>
                      {" "}
                      {_renderToggle(dashboard)}
                    </h4>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ padding: 12 }}>
                <Card.Text>{dashboard.description}</Card.Text>
              </Card.Body>
              <Card.Footer>
                <div className="d-grid gap-2">
                  <Button
                    className="rounded-pill"
                    variant="primary"
                    onClick={() => navigate(`/dashboard/${dashboard.id}`)}
                    disabled={dashboard.disabled}
                  >
                    {t("View")}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          ))}

        {canImpersonate && (
          <Card
            key="new-dash-card"
            style={{ width: "18rem", padding: 0, margin: "6px" }}
            className=""
          >
            <Card.Header style={{ flex: 3 }} className="d-flex h-100">
              <Row>
                <Col>
                  <h4>{t("Add New Dashboard")}</h4>{" "}
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Card.Header>
            <Card.Img
              variant="top"
              src="/static/dashboard.png"
              style={{
                objectFit: "contain",
                width: "auto",
                maxHeight: "100px",
              }}
              alt="https://www.flaticon.com/free-icons/dashboard; Dashboard icons created by Those Icons - Flaticon"
              className="bg-light mx-auto"
            />
            <Card.Footer className="">
              <div className="d-grid gap-2">
                <Button
                  className="rounded-pill"
                  variant="purple"
                  onClick={() => navigate(`/customers/select`)}
                >
                  {t("Create")}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        )}
      </Row>
    </Col>
  );
}
