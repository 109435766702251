import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateChartOptions, twoLinearYScales } from "../../../Utils/chartJsOptions";
import SingleBarChart from "../Simple/SingleBarChart";
import OverlayNumericCategoricalBarChart from "./OverlayNumericCategoricalBarChart";
import CategoricalSingleChart from "../Categorical/CategoricalSingleChart";
import OverlayNumericWeatherBarChart from "./OverlayNumericWeatherBarChart";
import { applyTargetAnnotations, calculateYMaxTarget } from "../../../Utils/utilFunctions";

export default function OverlayBarChart(props) {
  const { chartData, overlay, fraction } = props;

  let cleanData = JSON.parse(JSON.stringify(chartData)); // Create a deep copy
  let cleanOverlay = JSON.parse(JSON.stringify(overlay));
  let target = cleanData.target;
  let upper_benchmark = cleanData.upper_benchmark;
  let lower_benchmark = cleanData.lower_benchmark;

  if (!cleanOverlay || cleanOverlay.value === undefined || cleanOverlay.value === "No data.") {
    return <SingleBarChart chartData={cleanData} target={target} fraction={fraction} upper_benchmark={upper_benchmark} lower_benchmark={lower_benchmark} />;
  }
  if (cleanOverlay && cleanOverlay.type === "categorical") {
    if (cleanData.type === "sum") {
      return <CategoricalSingleChart chartData={cleanOverlay} dropdownSelection={"count"} barName={cleanData.name} target={target} />
    }
    return <OverlayNumericCategoricalBarChart chartData={cleanData} target={target} overlay={cleanOverlay} fraction={fraction} />
  }

  if (cleanOverlay && cleanOverlay.type === "weather") {
    return <OverlayNumericWeatherBarChart chartData={cleanData} target={target} overlay={cleanOverlay} fraction={fraction} />
  }

  // Handling the case for Gauge, which returns an array
  if (typeof overlay.cleanOverlay === "object" && Array.isArray(cleanOverlay.value)) {
    cleanOverlay.value = cleanOverlay.value[0];
  }
  const yMax = calculateYMaxTarget(chartData, target, upper_benchmark, lower_benchmark);
  let options = generateChartOptions({yMax: yMax});
  options.scales = twoLinearYScales(options, cleanData, cleanOverlay, yMax);
  options = applyTargetAnnotations(options, target, cleanData, upper_benchmark, lower_benchmark);

  const chartJsSetup = {
    labels: [cleanData.name],
    datasets: [
      {
        plotType: cleanData.type,
        label: cleanData.name,
        data: [cleanData.value],
        backgroundColor: getColor(0),
        borderRadius: 8,
        yAxisID: 'y'
      },
      {
        plotType: cleanOverlay.type,
        label: cleanOverlay.name,
        data: [cleanOverlay.value],
        backgroundColor: getColor(1), // Ensure this picks a different color
        borderRadius: 8,
        yAxisID: 'yR'
      }
    ]
  };

  return (
    <Chart
      type="bar"
      data={chartJsSetup}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
}
