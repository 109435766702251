// frontend/src/Router.js
import React, { useContext } from "react";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import useUrlSync from "./Hooks/useUrlSync";
import { MainContext } from "./Providers/MainContext";
import LoginPage from "./Page/LoginPage";
import LoginRedirect from "./Page/LoginRedirect";
import RegistrationPage from "./Page/Registration/RegistrationPage";
import ForgotPasswordPage from "./Page/ForgotPasswordPage";
import ResetPasswordPage from "./Page/ResetPasswordPage";
import DashboardListPage from "./Page/Dashboard/DashboardListPage";
import DashboardCreatePage from "./Page/Dashboard/DashboardCreatePage";
import DashboardEOCRExportPage from "./Page/Dashboard/DashboardEOCRExportPage";
import LoggedInAppBar from "./Components/LoggedInAppBar";
import DashboardPage from "./Page/Dashboard/DashboardPage";
import MetricPage from "./Page/Metric/MetricPage";
import PasswordUpdatePage from "./Page/PasswordUpdatePage";
import LocationPage from "./Page/Location/LocationPage";
import MerchPage from "./Page/Merch/MerchPage";
import JobPage from "./Page/JobPage";
import AdminSettingsPage from "./Page/AdminSettingsPage";
import DashboardEditPage from "./Page/Dashboard/DashboardEditPage";
import DashboardReportingSelectionPage from "./Page/Dashboard/DashboardReportingSelectionPage";
import CustomerCreatePage from "./Page/Customer/CustomerCreatePage";
import { CustomerListPage, CustomerSelectionPage } from "./Page/Customer/CustomerListPage";
import CustomerEditPage from "./Page/Customer/CustomerEditPage";
import MetricTemplateCreatePage from "./Page/MetricTemplateCreatePage";
import InviteUser from "./Page/User/InviteUser";

function UrlSync() {
  useUrlSync();
  return null;
}

export default function Router() {
  const { jwt, customer, canImpersonate, loading } = useContext(MainContext);
  const isCustomerAssigned = customer && Object.keys(customer).length > 0;
  const shouldNavigateInternally = !window.location.pathname.startsWith('/api/reports/'); // /api/reports are handled by the django template and should not be handled by react router
  return (
    // This is some fancy magic to make the router refresh when the url changes
    <div data-bs-theme="light">
      <BrowserRouter key={window.location.pathname}>
        <UrlSync />
        {!jwt && (
          <>
            <Routes>
              <Route path="/" element={<LoginPage />} state={{ from: window.location.pathname }} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
              <Route path="/set-password/:token" element={<ResetPasswordPage reset={false}/>} />
              {shouldNavigateInternally && <Route path="*" element={<Navigate to="/" state={{ from: window.location.pathname }} />} />}
            </Routes>
          </>
        )}
        {/* Need to make sure we're not loading (or a customer is assigned before adding the general redirect.
            Otherwise, before customer can be properly assigned, we are redirected away. */}
        {jwt && !isCustomerAssigned && !loading && (
          <>
            <LoggedInAppBar />
            <Routes>
              <Route path="/" element={<LoginRedirect state={{ from: window.location.pathname }} />} />
              {shouldNavigateInternally && <Route path="*" element={<Navigate to="/" state={{ from: window.location.pathname }} />} />}
              <Route path="/password/edit" element={<PasswordUpdatePage />} />
            </Routes>
          </>
        )}
        {jwt && isCustomerAssigned && (
          <>
            <LoggedInAppBar />
            <Routes>
              <Route path="/" element={<LoginRedirect state={{ from: window.location.pathname }} />} />
              {/* Dashboard Routes */}
              <Route path="/home" element={<DashboardListPage />} />
              <Route path="/dashboard" element={<DashboardListPage />} />
              <Route path="/dashboard-edit/:id" element={<DashboardEditPage />} />
              <Route path="/dashboard/:id" element={<DashboardPage />} />
              {/* Metric Routes */}
              <Route path="/metric/:id" element={<MetricPage />} />
              {/* Admin Routes */}
              <Route path="/admin-settings" element={<AdminSettingsPage />} />
              {/* Location Routes */}
              <Route path="/locations" element={<LocationPage />} />
              <Route path="/products" element={<MerchPage />} />
              <Route path="/invite-user" element={<InviteUser />} />
              <Route path="/dashboard/:id/eocr/export" element={<DashboardEOCRExportPage />} />
              {canImpersonate && (
                <>
                  {/* Dashboard Routes */}
                  <Route path="/dashboard-create" element={<DashboardCreatePage />} />
                  <Route path="/dashboard-reporting-selection/:id" element={<DashboardReportingSelectionPage />} />
                  {/* Customer Routes */}
                  <Route path="/customers/select" element={<CustomerSelectionPage />} />
                  <Route path="/customers/new" element={<CustomerCreatePage />} />
                  <Route path="/customers" element={<CustomerListPage />} />
                  <Route path="/customers/:id/edit" element={<CustomerEditPage />} />
                  <Route path="/metrictemplate-create" element={<MetricTemplateCreatePage />} />
                  {/* Job Routes */}
                  <Route path="/jobs" element={<JobPage />} />
                </>
              )}
              {/* Default Access Routes */}
              <Route path="/password/edit" element={<PasswordUpdatePage />} />
              {shouldNavigateInternally && <Route path="*" element={<Navigate to="/" state={{ from: window.location.pathname }} />} />}
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}
