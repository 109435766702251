// frontend/src/Hooks/useFilterStore.js
import { create } from "zustand";
import { SPLIT_CHARS } from "../config";

const initialState = {
  disabled: true,
  overlayOptions: new Array(),
  overlaySelection: "",
  groupBy: "",
  startdate: "",
  endDate: "",
  locations: new Set(),
  cities: new Set(),
  regions: new Set(),
  countries: new Set(),
  selectedLocations: new Array(),
  selectedCities: new Array(),
  selectedRegions: new Array(),
  selectedCountries: new Array(),
  brands: new Set(),
  categories: new Set(),
  products: new Set(),
  selectedBrands: new Array(),
  selectedCategories: new Array(),
  selectedProducts: new Array(),
  filterGroups: {},
  // Each key is a filter group label and its value is an array of selected options.
  selectedFilterGroups: {},
  isFiltersRetained: true,
  likedFirst: false,
  getUrlParams: () => new URLSearchParams(),
};

const recomputeUrlParamsFromState = (get) => {
  const {
    startdate,
    endDate,
    selectedLocations,
    selectedCities,
    selectedRegions,
    selectedCountries,
    selectedBrands,
    selectedCategories,
    selectedProducts,
    groupBy,
    selectedFilterGroups,
    overlaySelection,
  } = get();

  let urlObj = {};

  const addToUrlObj = (key, values) => {
    if (values.length > 0) {
      urlObj[key] = values.join(SPLIT_CHARS);
    }
  };

  if (startdate) urlObj.start_date = startdate;
  if (endDate) urlObj.end_date = endDate;

  addToUrlObj("locations", selectedLocations);
  addToUrlObj("cities", selectedCities);
  addToUrlObj("regions", selectedRegions);
  addToUrlObj("countries", selectedCountries);
  addToUrlObj("brands", selectedBrands);
  addToUrlObj("categories", selectedCategories);
  addToUrlObj("products", selectedProducts);

  if (groupBy) urlObj.group_by = groupBy;
  if (overlaySelection) urlObj.overlay_selection = overlaySelection;

  // For any filter group in selectedFilterGroups, add a query parameter.
  Object.entries(selectedFilterGroups).forEach(([group, values]) => {
    const arr = Array.isArray(values) ? values : Array.from(values);
    if (arr.length > 0) {
      urlObj[group] = arr.join(SPLIT_CHARS);
    }
  });
  return new URLSearchParams(urlObj);
};

const useFilterStore = create((set, get) => ({
  ...initialState,

  recomputeUrlParams: () => {
    const paramsString = recomputeUrlParamsFromState(get);
    set({
      getUrlParams: () => new URLSearchParams(paramsString),
    });
  },

  // Initialize store state from query parameters.
  initializeFromQueryParams: (params) => {
    const parseArray = (value) => (value ? new Array(value.split(SPLIT_CHARS)) : new Array());

    const startdate = params.get("start_date") || "";
    const endDate = params.get("end_date") || "";
    const groupBy = params.get("group_by") || "";
    const overlaySelection = params.get("overlay_selection") || "";
    const isFiltersRetained = params.get("is_filters_retained") === "true";
    const likedFirst = params.get("liked_first") === "true";

    let selectedLocations = parseArray(params.get("locations"));
    let selectedCities = parseArray(params.get("cities"));
    let selectedRegions = parseArray(params.get("regions"));
    let selectedCountries = parseArray(params.get("countries"));
    let selectedBrands = parseArray(params.get("brands"));
    let selectedCategories = parseArray(params.get("categories"));
    let selectedProducts = parseArray(params.get("products"));

    const knownKeys = new Set([
      "start_date",
      "end_date",
      "group_by",
      "overlay_selection",
      "is_filters_retained",
      "liked_first",
      "locations",
      "cities",
      "regions",
      "countries",
      "brands",
      "categories",
      "products",
    ]);

    const selectedFilterGroups = {};
    for (const [key, value] of params.entries()) {
      if (!knownKeys.has(key)) {
        selectedFilterGroups[key] = value.split(SPLIT_CHARS);
      }
    }

    set({
      startdate,
      endDate,
      groupBy,
      overlaySelection,
      isFiltersRetained,
      likedFirst,
      selectedLocations,
      selectedCities,
      selectedRegions,
      selectedCountries,
      selectedBrands,
      selectedCategories,
      selectedProducts,
      selectedFilterGroups,
    });
  },

  // --- Store setter actions ---
  setDisabled: (value) => {
    set({ disabled: value });
  },
  setOverlayOptions: (options) => {
    set({ overlayOptions: options });
  },
  setOverlaySelection: (selection) => {
    set({ overlaySelection: selection });
    get().recomputeUrlParams();
  },
  setGroupBy: (value) => {
    set({ groupBy: value });
    get().recomputeUrlParams();
  },
  setStartDate: (date) => {
    set({ startdate: date });
    get().recomputeUrlParams();
  },
  setEndDate: (date) => {
    set({ endDate: date });
    get().recomputeUrlParams();
  },
  setLocations: (locations) => {
    set({ locations });
  },
  setCities: (cities) => {
    set({ cities });
  },
  setRegions: (regions) => {
    set({ regions });
  },
  setCountries: (countries) => {
    set({ countries });
  },
  setSelectedLocations: (locations) => {
    set({ selectedLocations: locations });
    get().recomputeUrlParams();
  },
  setSelectedCities: (cities) => {
    set({ selectedCities: cities });
    get().recomputeUrlParams();
  },
  setSelectedRegions: (regions) => {
    set({ selectedRegions: regions });
    get().recomputeUrlParams();
  },
  setSelectedCountries: (countries) => {
    set({ selectedCountries: countries });
    get().recomputeUrlParams();
  },
  setBrands: (brands) => {
    set({ brands });
  },
  setCategories: (categories) => {
    set({ categories });
  },
  setProducts: (products) => {
    set({ products });
  },
  setSelectedBrands: (brands) => {
    set({ selectedBrands: brands });
    get().recomputeUrlParams();
  },
  setSelectedCategories: (categories) => {
    set({ selectedCategories: categories });
    get().recomputeUrlParams();
  },
  setSelectedProducts: (products) => {
    set({ selectedProducts: products });
    get().recomputeUrlParams();
  },
  setFilterGroups: (groups) => {
    set({ filterGroups: groups });
  },
  setSelectedFilterGroups: (groups) => {
    set({ selectedFilterGroups: groups });
    get().recomputeUrlParams();
  },
  handleToggleFilterModal: () => {
    set((state) => ({ filterOpen: !state.filterOpen }));
  },
  handleIsFiltersRetained: () => {
    set((state) => ({ isFiltersRetained: !state.isFiltersRetained }));
  },
  handleToggleLikedFirst: () => {
    set((state) => ({ likedFirst: !state.likedFirst }));
  },
  resetAllFilters: (retainFilters = false) => {
    set({
      ...initialState,
      isFiltersRetained: retainFilters,
    });
    get().recomputeUrlParams();
  },
  resetSelectedFilters: (retainFilters = false) => {
    set({
      disabled: true,
      overlaySelection: "",
      groupBy: "",
      startdate: "",
      endDate: "",
      selectedLocations: new Array(),
      selectedCities: new Array(),
      selectedRegions: new Array(),
      selectedCountries: new Array(),
      selectedBrands: new Array(),
      selectedCategories: new Array(),
      selectedProducts: new Array(),
      selectedFilterGroups: {},
      likedFirst: false,
      isFiltersRetained: retainFilters,
    });
    get().recomputeUrlParams();
  },

}));

export default useFilterStore;
