// frontend/src/Components/InviteUser.js
import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import UserFieldsForm from "../../Components/UserFieldsForm";
import ProfileFieldsForm from "../../Components/ProfileFieldsForm";
import { validateUserFields } from "../../Utils/validation";
import { MainContext } from "../../Providers/MainContext";
import useFetchConfig from "../../Hooks/useFetchConfig";

export default function InviteUser() {
  const { customer, canImpersonate, setImpersonatedCustomerId } = useContext(MainContext);
  const { t } = useTranslation();  
  const fetchConfig = useFetchConfig();
  const { get, post, response } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);
  const [userFields, setUserFields] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [profileFields, setProfileFields] = useState({
    customer_id: customer.id,
    customer_admin: false,
    dashboard_admin_ids: [],
    dashboard_viewer_ids: [],
    mca: false,
    mca_customers: [],
    lang: "EN",
  });
  const [errors, setErrors] = useState({});
  const [dashboards, setDashboards] = useState([]);
  const [customers, setCustomers] = useState([]);

  const fetchDashboards = async () => {
    const dashes = await get(`/api/customer/${customer.id}/dashboards`);
    if (response.ok) {
      setDashboards(dashes);
    }
  };

  const fetchCustomers = async () => {
    if (canImpersonate) {
      const custs = await get(`/api/customer/all`);
      if (response.ok) {
        setCustomers(custs);
      }
    } else {
      setCustomers([]);
    }
  };

  useEffect(() => {
    fetchDashboards();
  }, [customer.id]);

  useEffect(() => {
    fetchCustomers();
  }, [canImpersonate]);

  const handleChange = (e) => {
    const { name, value, type, checked, options } = e.target;
    let fieldValue;
    
    if (type === "checkbox") {
      fieldValue = checked;
    } else if (type === "select-multiple") {
      fieldValue = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
    } else {
      fieldValue = value;
    }
    
    if (userFields.hasOwnProperty(name)) {
      setUserFields({ ...userFields, [name]: fieldValue });
    } else {
      setProfileFields({ ...profileFields, [name]: fieldValue });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userErrors = validateUserFields(userFields);
    setErrors(userErrors);
    if (Object.keys(userErrors).length) {
      return;
    }
    const data = await post("/api/user-with-profile", {
      ...userFields,
      ...profileFields,
    });
    if (response.ok) {
      setUserFields({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
      });
      setProfileFields({
        customer_id: customer.id,
        customer_admin: false,
        dashboard_admin_ids: [],
        dashboard_viewer_ids: [],
        mca: false,
        mca_customers: [],
        lang: "EN",
      })
      // Reload customer so we reload admins in Admin Settings.
      setImpersonatedCustomerId(customer.id);
    } else {
      setErrors({ submission: data.error });
    }
  };

  return (
    <Container>
      <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
        <h1 className="text-light">{t("Invite User")}</h1>
        <Form onSubmit={handleSubmit}>
          <UserFieldsForm
            fields={userFields}
            errors={errors}
            onChange={handleChange}
          />
          <ProfileFieldsForm
            profile={profileFields}
            errors={errors}
            dashboards={dashboards}
            customers={customers}
            onChange={handleChange}
          />
          <Button type="submit">{t("Invite User")}</Button>
          <p className="text-danger" ><small>{errors["submission"]}</small></p>
        </Form>
      </Col>
    </Container>
  );
}
