// frontend/src/Components/LabelledSearchableSwitchDropdown.js
import React from "react";
import SearchableSwitchDropdown from "./SearchableSwitchDropdown";

export default function LabelledSearchableSwitchDropdown({ 
  options, 
  switchStates, 
  onSwitchChange, 
  placeholder, 
  variant, 
  label, 
  displayOptionsFunc 
}) {
  // Wrap the onSwitchChange callback to include the label context, if provided
  const handleOnSwitchChange = (option, isChecked) => {
    if (label) {
      onSwitchChange(option, isChecked, label);
    } else {
      onSwitchChange(option, isChecked);
    }
  };

  return (
    <SearchableSwitchDropdown
      options={options}                  // Pass original options
      switchStates={switchStates}        // Pass switch states for tracking
      onSwitchChange={handleOnSwitchChange} // Include label if present
      placeholder={placeholder}          // Placeholder text
      variant={variant}                  // Button variant
      displayOptionsFunc={displayOptionsFunc} // Transformation function
    />
  );
}
