import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  chartJsDefaultOptions,
  createCategoricalLabels,
} from "../../../Utils/chartJsOptions";
import { prepareCategoricalData } from "../../../Utils/utilFunctions";

export default function CategoricalSingleChart(props) {
  const { chartData, dropdownSelection, barName, target } = props;
  if (chartData.value === undefined || !Array.isArray(chartData.value))
    return <></>;

  // Update chartData based on selection
  chartData.value = prepareCategoricalData(chartData.value, dropdownSelection);

  // other default charts
  const options = {
    ...chartJsDefaultOptions,
    plugins: {
      datalabels: {
        ...chartJsDefaultOptions.plugins.datalabels,
        labels: createCategoricalLabels(dropdownSelection),
      },
    },
  };
  const chartJsSetup = {};
  let chartType;

  if (dropdownSelection === "pie") {
    chartType = "pie";
    options.aspectRatio = 2;
    chartJsSetup.labels = chartData.value.map((item) => item.value);
    chartJsSetup.datasets = [
      chartData.value.reduce(
        (acc, value, index) => {
          acc.data.push([value.displayValue]);
          acc.count.push([value.dcount]);
          acc.backgroundColor.push(getColor(index));
          return acc;
        },
        { data: [], count: [], backgroundColor: [] },
      ),
    ]
  } else {
    chartType = "bar";
    options.scales = {
      ...options.scales,
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: dropdownSelection === "percentage" ? 100 : undefined, // Set max to 100 for percentage
      },
    };
    chartJsSetup.labels = [barName ? barName : chartData.name];
    chartJsSetup.datasets = chartData.value.map((value, index) => ({
      label: value.value,
      data: [value.displayValue],
      count: [value.dcount],  // Used for datalabels
      backgroundColor: getColor(index),
      borderRadius: 8,
    }))
  }

  return (
    <Chart
      type={chartType}
      data={chartJsSetup}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
}
