import i18n from 'i18next';
import React, { useEffect, useState } from "react";
import useCookie from "react-use-cookie";

const MainContext = React.createContext();

function MainContextProvider({ children }) {
  const [jwt, setJwt] = useCookie("token", "");
  const [impersonatedCustomerId, setImpersonatedCustomerId] = useState(null);
  const [canImpersonate, setCanImpersonate] = useState(false);
  const [user, setUser] = useState({});
  const [customer, setCustomer] = useState({});
  const [lang, setLang] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    _fetchUser();
    // eslint-disable-next-line
  }, [jwt, impersonatedCustomerId]);

  const _fetchUser = async () => {
    if (!jwt) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let baseUrl = process.env.REACT_APP_API_URL || "";
    let userProfile = await fetch(baseUrl + "/api/me", {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        ...(impersonatedCustomerId && { customer: impersonatedCustomerId }),
      },
    });
    if (userProfile.ok) {
      userProfile = await userProfile.json();
      setCanImpersonate(userProfile.is_multi_customer_admin);
      setUser(userProfile.user);
      setCustomer(userProfile.customer);
      if (userProfile.lang) {
        setLang(userProfile.lang);
        const languageCode = userProfile.lang.toLowerCase();
        i18n.changeLanguage(languageCode);
      }
    } else {
      console.error("Error fetching user profile");
      setCanImpersonate(false);
      setUser({});
      setCustomer({});
      setLang(null);
    }

    setLoading(false);
  };

  return (
    <MainContext.Provider
      value={{
        jwt,
        setJwt,
        user,
        setUser,
        customer,
        setCustomer,
        lang,
        setLang,
        loading,
        canImpersonate,
        setCanImpersonate,
        impersonatedCustomerId,
        setImpersonatedCustomerId,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export { MainContextProvider, MainContext };
