import React from "react";
import { Modal, Button } from "react-bootstrap";

const QuickMessagePopup = ({ show, onHide, title, message }) => {
  return (
    <Modal show={show} onHide={onHide} centered >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">{message}</Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="primary" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuickMessagePopup;
