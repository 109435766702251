import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";
import { useTranslation } from "react-i18next";

export function EditInsight({
  dashboardId,
  insight,
  editModalOpen,
  setEditModalOpen,
  onUpdatePrimary,
  maxPriority,
}) {
  const fetchConfig = useFetchConfig();
  const { patch, del, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [error, setError] = useState(null);
  const [priority, setPriority] = useState(maxPriority + 1);
  const [insightText, setInsightText] = useState("");
  
  const { t } = useTranslation();

  useEffect(() => {
    if (insight.insight) {
        setInsightText(insight.insight);
    }
  }, [insight])

  useEffect(() => {
    setPriority(maxPriority + 1)
  }, [maxPriority]);

  const handleSave = async () => {
    // Make an API call here to update the primary_start and primary_end dates
    // Assuming the API call is successful, trigger the callback
    let payload = {
      id: insight.id,
      insight: insightText,
      priority: priority,
    };
    let data = await patch(`/api/dashboard/${dashboardId}/insights`, payload);
    if (response.ok) {
      setError(null);
      onUpdatePrimary(data);
      setEditModalOpen(false);
    } else {
      setError(data?.error || "Something went wrong with the update.");
    }
  };

  const handleDelete = async () => {
    // extra confirmation before deleting
    if (window.confirm(t("Are you sure you want to delete this insight?"))) {
      const data = await del(`/api/dashboard/${dashboardId}/insights/${insight.id}`);
      if (response.ok) {
        setError(null);
        onUpdatePrimary(data);
        setEditModalOpen(false);
      } else {
        setError(data?.error || "Something went wrong while deleting the insight.");
      }
    }
  };

  return (
    <Modal show={editModalOpen} onHide={() => setEditModalOpen(false)}>
      <Modal.Header className="bg-light">
        <Modal.Title>
          {`Edit insight ${insight.id}:`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <div className="alert alert-danger">{error}</div>}{" "}
        {/* Display error message */}
        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="start">
              <Form.Label>{t("Insight")}</Form.Label>
              <Form.Control
                type="textarea"
                value={insightText}
                onChange={(e) => setInsightText(e.target.value)}
                placeholder='e.g. "Change X was effective because we noticed Y increased by an average of Z for the 5 responses afterwards when compared with the 12 before.'
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="priority">
              <Form.Label>{t("Priority")}</Form.Label>
              <Form.Control
                type="number"
                value={priority}
                onChange={(e) =>
                  setPriority(parseInt(e.target.value, 10) || "")
                }
                className="bg-light"
                min="1"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={() => setEditModalOpen(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {t("Delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
