import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import { ArrowLeft, Download } from "react-bootstrap-icons";

const DashboardEOCRExportPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const [status, setStatus] = useState("initiating");
  const [taskId, setTaskId] = useState(null);
  const [error, setError] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);

  // Use relative URL from the current domain base instead of environment variable
  const { get, response } = useFetch(
    "", // Empty string will use the current domain as base URL
    fetchConfig,
  );

  useEffect(() => {
    const initiateExport = async () => {
      try {
        const result = await get(`/api/reports/dashboard/${id}/eocr/export`);
        if (response.ok) {
          setTaskId(result.task_id);
          setStatus(result.status);
          setError(null);
        } else {
          setError(result);
        }
      } catch (error) {
        console.error(error);
        setError("Error initiating export");
      }
    };

    initiateExport();

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [id]);

  useEffect(() => {
    if (taskId) {
      const interval = setInterval(async () => {
        try {
          const result = await get(
            `/api/reports/task/${taskId}/dashboard/${id}/pdf`,
          );
          if (response.ok) {
            if (result.status) {
              setStatus(result.status);
            } else {
              // Task is complete and has returned data
              setStatus("SUCCESS");
              clearInterval(interval);
              // Automatically download the PDF
              downloadPdf();
            }
          } else {
            setError(result);
          }
        } catch (error) {
          console.error(error);
          setStatus("FAILURE");
          setError("Error checking task status");
          clearInterval(interval);
        }
      }, 2000);

      setPollingInterval(interval);

      return () => {
        clearInterval(interval);
      };
    }
  }, [taskId]);

  const downloadPdf = () => {
    if (taskId && status === "SUCCESS") {
      try {
        // Use absolute path from the root of the domain, removing dependency on REACT_APP_API_URL
        const pdfUrl = `/api/reports/task/${taskId}/dashboard/${id}/pdf`;
        // console.log("Opening PDF URL:", pdfUrl); // For debugging
        // window.open(pdfUrl, "_blank");

        const downloadLink = document.createElement('a');
        downloadLink.href = `/api/reports/task/${taskId}/dashboard/${id}/pdf`;
        // Removed the download attribute to use server-provided filename
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Primary download method failed:", error);
        setError(error);
      }
    }
  };

  const goBack = () => {
    navigate(`/dashboard/${id}`);
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={4}>
          <Card>
            <Card.Header>
              <h4 className="mb-0">EOCR Export</h4>
            </Card.Header>
            <Card.Body>
              {error && (
                <Alert variant="danger" className="mb-3">
                  {error}
                </Alert>
              )}

              <div className="mb-3">
                {!error && (
                  <>
                    <p>
                      {status === "initiating" && "Initiating PDF export..."}
                      {status === "PENDING" && "PDF export in progress..."}
                      {status === "SUCCESS" &&
                        "PDF export completed successfully!"}
                      {status === "FAILURE" && "PDF export failed."}
                    </p>

                    {(status === "initiating" || status === "PENDING") && (
                      <ProgressBar animated now={100} className="mb-3" />
                    )}
                  </>
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={goBack}
                    className="me-2"
                  >
                    <ArrowLeft className="me-1" /> Back
                  </Button>
                </Col>
                <Col className="text-end">
                  {status === "SUCCESS" && (
                    <Button variant="primary" onClick={downloadPdf}>
                      <Download className="me-1" /> Download PDF
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardEOCRExportPage;
