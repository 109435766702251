// frontend/src/Page/Registration/RegistrationPage.js
import React, { useState, useContext } from "react";
import { Form, Button, Container, Alert, Col, Row } from "react-bootstrap";
import { MainContext } from "../../Providers/MainContext";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import UserFieldsForm from "../../Components/UserFieldsForm";
import { validateUserFields } from "../../Utils/validation";

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const { setJwt } = useContext(MainContext);
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: "no-cache",
  });
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormErrors({ ...error, [e.target.name]: "" })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateUserFields(formData, {}, true, t);
    setFormErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    
    let data = await post("/api/registration/", formData);
    if (!response.ok) {
      setFormErrors({ ...formErrors, username: response.data?.username?.[0] || "" });
      return;
    }

    setError(null);
    setJwt(data.access, {
      days: 1,
      SameSite: "Strict",
      Secure: true,
    });
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
          <h2 className="text-white">{t("Registration")}</h2>
        </Col>
      </Row>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <UserFieldsForm fields={formData} errors={formErrors} onChange={handleChange} includePassword={true} />
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Button variant="primary" type="submit">
              {t("Sign Up")}
            </Button>
          </Col>
        </Row>
        {error && <Alert variant="danger">{error}</Alert>}
      </Form>
    </Container>
  );
};

export default RegistrationPage;
