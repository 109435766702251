// frontend/src/Components/UserFieldsForm.js
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function UserFieldsForm({ fields, errors, onChange, includePassword = false }) {
  const { t } = useTranslation();
  return (
    <>
      {Object.entries(fields).map(([key, value]) => (
        key === "password" && !includePassword ? null : (
          <Form.Group className="mb-3" key={key}>
            <Form.Label className="text-light" style={{ textTransform: "capitalize" }}>
              {t(key.replace("_", " "))}
            </Form.Label>
            <Form.Control
              type={key === "password" ? "password" : "text"}
              name={key}
              value={value}
              onChange={onChange}
              isInvalid={!!errors[key]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[key]}
            </Form.Control.Feedback>
          </Form.Group>
        )
      ))}
    </>
  );
}