import React, { useState, useContext } from "react";
import { Form, Button, Container, Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import useFetchConfig from "../Hooks/useFetchConfig";
import { useNavigate } from "react-router-dom";
import { passwordRegex } from "../Utils/Regex";

const PasswordUpdatePage = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const fetchConfig = useFetchConfig();
  const { post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Optionally reset errors
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const validate = () => {
    const newErrors = {};

    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.";
    }
    if (formData.new_password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
      newErrors.new_password = "Passwords do not match";
    }
    // New Password validation
    if (!formData.new_password) {
      newErrors.new_password = "New Password is required";
    } else if (!passwordRegex.test(formData.new_password)) {
      newErrors.new_password =
        "New Password must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.";
    }
    if (!formData.confirm_password) {
      newErrors.confirm_password = "Confirm Password is required";
    } else if (!passwordRegex.test(formData.confirm_password)) {
      newErrors.confirm_password =
        "Confirm Password must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.";
    }

    // add a logic to retrieve the password and check if it is the same as the current password
    // if not, add an error to the new_password and confirm_password

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!validate()) {
      e.stopPropagation();
      setValidated(false); // Set validated to False to trigger Bootstrap's validation styles
      return; // Stop submission since there are errors
    }

    let data = await post("/api/password/edit/", formData);

    if (!response.ok) {
      if (response.data.password === "Wrong password.") {
        setFormErrors({ ...formErrors, password: "Wrong Password" });
      }

      return;
    } else {
      alert("Password Updated");
      navigate("/dashboard");
    }
  };

  return (
    <Container className="mt-5">
      {/* make the h2 middle */}

      <Row>
        <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
          <h2 className="text-white">{t("Change Password")}</h2>
        </Col>
      </Row>
      <Form noValidate onSubmit={handleSubmit}>
        {/* Username
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3" >
              <Form.Label className='text-white'>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                name="username"
                value={formData.username}
                // onChange={handleChange}
                // isInvalid={!!formErrors.username}
                disabled

              />
              <Form.Control.Feedback type="invalid">
                {formErrors.username}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> */}

        {/* Password */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Current Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Current Password")}
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!formErrors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("New Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("New Password")}
                name="new_password"
                value={formData.new_password}
                onChange={handleChange}
                isInvalid={!!formErrors.new_password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.new_password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Confirm Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Confirm Password")}
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                isInvalid={!!formErrors.confirm_password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.confirm_password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Submit Button */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Button variant="primary" type="submit">
              {t("Update")}
            </Button>
          </Col>
        </Row>
        {/* Error */}
        {error && <Alert variant="danger">{error}</Alert>}
      </Form>
    </Container>
  );
};

export default PasswordUpdatePage;
